
export const LOAD_BLOG          = 'LOAD_BLOG';
export const LOAD_BLOG_SUCCESS  = 'LOAD_BLOG_SUCCESS';
export const LOAD_BLOG_FAILED   = 'LOAD_BLOG_FAILED';


export const loadBlogSuccess = (data: any) => ({
  type    : LOAD_BLOG_SUCCESS,
  payload : data,
});

export const loadBlogFailed = (error: string) => ({
  type    : LOAD_BLOG_FAILED,
  payload : error,
});
