import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  LOAD_APP_CONFIG, loadAppConfigSuccess, loadAppConfigFailed,
          LOAD_CONTENT_HOME, loadContentHomeSuccess, loadContentHomeFailed,
          LOAD_FEED, loadFeedSuccess, loadFeedFailed,
          LOAD_SOCIAL_POST, loadSocialPostSuccess, loadSocialPostFailed,
          LOAD_LATEST_NEWS, loadLatestNewsSuccess, loadLatestNewsFailed,
          LOAD_LATEST_EVENTS, loadLatestEventsSuccess, loadLatestEventsFailed,
          SEND_EMAIL_CONTACT_US, sendEmailSuccess, sendEmailFailed } from './actions';

const apiUrl = process.env.REACT_APP_API_URL;

//////////////////////////
// loadAppConfig()
//////////////////////////
function* loadAppConfig(action: any) {
  try {
    //console.log('home/saga::loadAppConfig()');
    var appCdn    = action.payload.appCdn;
    var appConfig =  appCdn+ '/config.json';
    //console.log('appConfig : '+ appConfig);
    const { data } = yield call(axios.get, appConfig, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(loadAppConfigSuccess(data));    
  } catch (error) {
    //yield put(sendEmailFailure(error.message));
    yield put(loadAppConfigFailed('An issue was encountered while loading app config.'));
  }
}

//////////////////////////
// loadContentHome()
//////////////////////////
function* loadContentHome(action: any) {
  try {
    //console.log('home/saga::loadContentHome()');
    var appCdn    = action.payload.appCdn;

    //=temp only
    //appCdn = 'https://cdn.instantapp.dev/shihtzu';

    var contentHome =  appCdn+ '/content/home.json';
    const { data } = yield call(axios.get, contentHome, action.payload);
    yield put(loadContentHomeSuccess(data));    
  } catch (error) {
    yield put(loadContentHomeFailed('An issue was encountered while loading content for homepage.'));
  }
}

//////////////////////////
// loadFeed()
//////////////////////////
function* loadFeed(action: any) {
  try {
    const { data } = yield call(axios.post, apiUrl+ 'feed/current', action.payload);
    yield put(loadFeedSuccess(data));    
  } catch (error) {
    //yield put(sendEmailFailure(error.message));
    yield put(loadFeedFailed('An issue was encountered while loading feed.'));
  }
}

//////////////////////////
// loadSocialPost()
//////////////////////////
function* loadSocialPost(action: any) {
  try {
    const { data } = yield call(axios.post, apiUrl+ 'socialpost/current', action.payload);
    yield put(loadSocialPostSuccess(data));    
  } catch (error) {
    //yield put(sendEmailFailure(error.message));
    yield put(loadSocialPostFailed('An issue was encountered while loading social posts.'));
  }
}

//////////////////////////
// loadLatestNews()
//////////////////////////
function* loadLatestNews(action: any) {
  try {
    const { data } = yield call(axios.post, apiUrl+ 'news/latest', action.payload);
    yield put(loadLatestNewsSuccess(data));    
  } catch (error) {
    yield put(loadLatestNewsFailed('An issue was encountered while loading latst news.'));
  }
}

//////////////////////////
// loadLatestEvents()
//////////////////////////
function* loadLatestEvents(action: any) {
  try {
    /*
    //const { data } = yield call(axios.post, apiUrl+ 'events/latest', action.payload);
    //const { data } = yield call(axios.post, apiUrl+ 'news/test', action.payload);
    const { data } = yield call(axios.get, apiUrl+ 'news/test', action.payload);
    yield put(loadLatestEventsSuccess(data));    
    */
  } catch (error) {
    yield put(loadLatestEventsFailed('An issue was encountered while loading latest events.'));
  }
}

//////////////////////////
// sendEmailContactUs()
//////////////////////////
function* sendEmailContactUs(action: any) {
  try {
    //console.log('home/saga::sendEmailContactUs()');
    //console.log('apiUrl:'+ apiUrl);
    //yield put({ type: SEND_EMAIL_CONTACT_US });
    yield call(axios.post, apiUrl+ 'email/contactus', action.payload);
    yield put(sendEmailSuccess());
  } catch (error) {
    //yield put(sendEmailFailure(error.message));
    yield put(sendEmailFailed('An issue was encountered while sending email.'));
  }
}

//////////////////////////
// rootSaga()
//////////////////////////
export default function* rootSaga() {
  //yield watchSendEmailContactUs();
  yield all([takeLatest(LOAD_APP_CONFIG, loadAppConfig)]);
  yield all([takeLatest(LOAD_CONTENT_HOME, loadContentHome)]);
  yield all([takeLatest(LOAD_FEED, loadFeed)]);
  yield all([takeLatest(LOAD_SOCIAL_POST, loadSocialPost)]);
  yield all([takeLatest(LOAD_LATEST_NEWS, loadLatestNews)]);
  yield all([takeLatest(LOAD_LATEST_EVENTS, loadLatestEvents)]);
  yield all([takeLatest(SEND_EMAIL_CONTACT_US, sendEmailContactUs)]);
}
