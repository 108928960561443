import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import ReactHtmlParser, { processNodes, convertNodeToElement } from 'react-html-parser';

import LayoutMaster from '../../layouts/LayoutMaster';

import { LOAD_NEWS } from '../../store/news/actions';

const NewsMain = (props:any) => {

    const center = {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingTop: '20px'
        //height: '100vh'
      };

    const dispatch = useDispatch();

    // useEffect
    useEffect(() => {
        try {
            /*
            console.log('useEffect()');
            console.log('news_id');
            */
            console.log(props.match.params.news_id);
            var data = {
                news_id : props.match.params.news_id
            };
            dispatch({
                type    : LOAD_NEWS,
                payload : data,
            });   
        } catch (error) {
          console.log(error);
        }    
    }, [props.match.params.news_id]);
    
    // redirect
    if (props.news != null) {
        if (props.news.link != '') {
            console.log('props.news.link');
            console.log(props.news.link);
            window.location.replace(props.news.link);
            //return;    
        }
    }

    return (
        <LayoutMaster>
            <div className="tw-flex tw-justify-center" style={{padding: '50px 25px 10px 25px'}}>
                <div className = "tw-text-blue-900 tw-bg-sky-200/75 tw-rounded-full" style={{padding: '10px 20px 10px 20px'}}>
                    {props.news.link != '' ? (
                        <>
                            Redirecting to {props.news.link}
                        </>
                    ) : (
                        <>
                            Redirecting...
                        </>
                    )}
                </div>
            </div>
        </LayoutMaster>
    );

}


const mapStateToProps = (state: any) => {
    return {
        news : state.newsReducer.news,
    };
}

export default connect(mapStateToProps)(NewsMain);
