import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeMain from '../pages/home/HomeMain';
import CaringMain from '../pages/caring/CaringMain';
import AboutMain from '../pages/about/AboutMain';
import PostsMain from '../pages/posts/PostsMain';
import ContactMain from '../pages/contact/ContactMain';
import Article from '../pages/article/ArticleMain';
import Blog from '../pages/blog/BlogMain';
import News from '../pages/news/NewsMain';
import PrivacyMain from '../pages/privacy/PrivacyMain';
import TermsMain from '../pages/terms/TermsMain';

class AppRoutes extends Component {
    render() {
        return (
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path="/" component={HomeMain} />
                        <Route exact path="/about" component={AboutMain} />
                        <Route exact path="/caring" component={CaringMain} />
                        <Route exact path="/terms" component={TermsMain} />
                        <Route exact path="/privacy" component={PrivacyMain} />
                        <Route exact path="/posts" component={PostsMain} />
                        <Route exact path="/contact" component={ContactMain} />
                        <Route exact path="/article/:slug" component={Article} />
                        <Route exact path="/blog/:slug" component={Blog} />
                        <Route exact path="/news/:news_id" component={News} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

export default AppRoutes;
