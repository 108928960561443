
export const LOAD_ARTICLE          = 'LOAD_ARTICLE';
export const LOAD_ARTICLE_SUCCESS  = 'LOAD_ARTICLE_SUCCESS';
export const LOAD_ARTICLE_FAILED   = 'LOAD_ARTICLE_FAILED';


export const loadArticleSuccess = (data: any) => ({
  type    : LOAD_ARTICLE_SUCCESS,
  payload : data,
});

export const loadArticleFailed = (error: string) => ({
  type    : LOAD_ARTICLE_FAILED,
  payload : error,
});
