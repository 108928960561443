import React, { useEffect } from 'react';
import $ from 'jquery';

import Header from './Header';
import Footer from './Footer';

const LayoutMaster = (props:any) => {
    
    ///////////////////////////
    // useEffect()
    ///////////////////////////
    useEffect(() => {
        // scrollup function
        $(window).scroll(function () {
            // @ts-ignore
            if ($(this).scrollTop() > 40) {
                $('.navbar').addClass('sticky-top');
            } else {
                $('.navbar').removeClass('sticky-top');
            }
        });  

      }, [])

    ///////////////////////////
    // scrollToTop
    ///////////////////////////
    const scrollToTop = () =>{ 
        console.log('scrollToTop()');
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
    }; 
    
    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <div>
            <Header />

            {props.children}
        
            <Footer />
        
            {/* Back to Top */}
            <a onClick={scrollToTop} className="btn btn-primary py-3 fs-4 back-to-top"><i className="bi bi-arrow-up"></i></a>
        
            {/* JavaScript Libraries */}
            {/*
            <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
            <script src="lib/easing/easing.min.js"></script>
            <script src="lib/waypoints/waypoints.min.js"></script>
            <script src="lib/owlcarousel/owl.carousel.min.js"></script>   
            */}

        </div>
    );
}

export default LayoutMaster;