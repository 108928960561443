import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import LayoutMaster from '../../layouts/LayoutMaster';

import { LOAD_BLOG } from '../../store/blog/actions';

const BlogMain = (props:any) => {

    const center = {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingTop: '20px',
      };

    const dispatch = useDispatch();

    // useEffect()
    useEffect(() => {
        try {
            var data = {
                slug : props.match.params.slug
            };
            dispatch({
                type    : LOAD_BLOG,
                payload : data,
            });          
        } catch (error) {
          console.log(error);
        }    
    }, []);
    
    return (
        <LayoutMaster>
            <div style={center}>
                <h1 style={center}>{props.blog.title}</h1>
            </div>
            <div>
                {props.blog.content}
            </div>
        </LayoutMaster>
    );

}


// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {    blog : state.blogReducer.blog,
    };
}

export default connect(mapStateToProps)(BlogMain);
