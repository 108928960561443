
export const LOAD_NEWS          = 'LOAD_NEWS';
export const LOAD_NEWS_SUCCESS  = 'LOAD_NEWS_SUCCESS';
export const LOAD_NEWS_FAILED   = 'LOAD_NEWS_FAILED';


export const loadNewsSuccess = (data: any) => ({
  type    : LOAD_NEWS_SUCCESS,
  payload : data,
});

export const loadNewsFailed = (error: string) => ({
  type    : LOAD_NEWS_FAILED,
  payload : error,
});
