import {  toast } from 'react-toastify';
import {  LOAD_NEWS_SUCCESS,
          LOAD_NEWS_FAILED} from './actions';

       
const initialState = {
  news     : {
    news_id   : '',
    link      : '',
  },
  message     : '',   
  messageType : '',
}


//////////////////////////
// Reducer
//////////////////////////
function Reducer(state = initialState, action: any) {
  
  switch (action.type) {

    case LOAD_NEWS_SUCCESS:
      //console.log('news.reducer');
      //console.log('action.payload');
      //console.log(action.payload);
      return { 
        ...state, 
        news : action.payload,
      };

    case LOAD_NEWS_FAILED:
      toast("An issue was encountered while loading the news.");
      return { 
        ...state, 
        news: {
          news_id   : '',
          link      : '',      
        },
        messageType : 'error',   
        message     : 'An issue was encountered while loading the news.',
      };
      
    default:
      return state;
  }
}

export default Reducer;
