import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store/store';
import AppRoutes from './routes/AppRoutes';
import ReactGA from "react-ga4";

//import './styles.scss';

// Libraries Stylesheet 
/*
*/
import './lib/flaticon/font/flaticon.css';
import './lib/owlcarousel/assets/owl.carousel.min.css';

// font awesome
import './lib/fontawesome-6.5.1/css/fontawesome.css';
import './lib/fontawesome-6.5.1/css/brands.css';
import './lib/fontawesome-6.5.1/css/solid.css';

import './css/style.common.css';
import './css/style.embed.css';
import './css/tw-tailwind.css';


class App extends Component {

  ///////////////////////////
  // componentDidMount()
  ///////////////////////////
  componentDidMount() {
    console.log('App.componentDidMount() begin');
    const appName   = process.env.REACT_APP_APP_NAME;
    //console.log('appName:'+ appName);
    const gTagMeasId = String(process.env.REACT_APP_GTAG_MEASUREMENT_ID);
    //console.log('gTagMeasId');
    //console.log(gTagMeasId);
    ReactGA.initialize(gTagMeasId, {});
    import(`./css/bootstrap.min.${appName}.css`);
    import(`./css/style.${appName}.css`);
  }

  ///////////////////////////
  // render()
  ///////////////////////////
  render() { 
    return (
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    );
  }

}

export default App;
