import {  toast } from 'react-toastify';
import {  LOAD_BLOG_SUCCESS,
          LOAD_BLOG_FAILED} from './actions';

       
const initialState = {
  blog     : {
    title     : '',
    content   : '',
    summary   : '',
    slug      : '',
  },
  message     : '',   
  messageType : '',
}


//////////////////////////
// Reducer
//////////////////////////
function Reducer(state = initialState, action: any) {
  
  switch (action.type) {

    case LOAD_BLOG_SUCCESS:
      /*
      console.log('blog.reducer');
      console.log('action.payload');
      */
      return { 
        ...state, 
        blog : action.payload,
      };

    case LOAD_BLOG_FAILED:
      toast("An issue was encountered while loading the blog.");
      return { 
        ...state, 
        blog: {
          title     : '',
          content   : 'An issue was encountered while loading the blog.',
          summary   : '',
          slug      : '',      
        },
        messageType : 'error',   
        message     : 'An issue was encountered while loading the blog.',
      };
      
    default:
      return state;
  }
}

export default Reducer;
