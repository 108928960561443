/**
 * Task types
 */
export enum TaskTypes {
    ADD_TASK        = 'ADD_TASK',
    TOGGLE_TASK     = 'TOGGLE_TASK',
    TOGGLE_EDIT_TASK= 'TOGGLE_EDIT_TASK',
    UPDATE_TASK     = 'UPDATE_TASK',
    REMOVE_TASK     = 'REMOVE_TASK',
    LOAD_REQUEST    = 'LOAD_REQUEST',
    LOAD_SUCCESS    = 'LOAD_SUCCESS',
    LOAD_FAILURE    = 'LOAD_FAILURE'
  }

export enum SourceCode {
    Instagram       = 'IG',
    Pinterest       = 'PI',
    TikTok          = 'TT',
    Twitter         = 'TW',
    YouTube         = 'YT'
}

export enum FeedType {
    News            = 'NE',
    SocialPost      = 'SP'
}

/**
 * Data types
 */
export interface Task {
    id              : number,
    text            : string,
    editing         : boolean,
    complete        : boolean
}
  
/**
 * TaskListActionTypes
*/
export interface TaskListActionTypes {
    type            : string,
    payload         : Task,
    data            : Task[]
}


/**
 * TaskState
 */
export interface TaskState {
    readonly data   : Task[]
}


/**
 * Filter options
 */
export enum VisibilityFilters {
    SHOW_ALL        = 'SHOW_ALL',
    SHOW_ACTIVE     = 'SHOW_ACTIVE',
    SHOW_COMPLETED  = 'SHOW_COMPLETED',
    UPDATE_FILTER   = 'UPDATE_FILTER'
}

export interface FilterActionTypes {
    type            : string,
    payload         : {
        filter : string
    }
}

export interface TaskState {
    readonly data   : Task[]
}

export interface ApplicationState {
    tasks           : TaskState,
    filterState     : string
}

export interface AppConfigState {
    appName       : string,
    siteName      : string,
    contactEmail  : string,
    breedName     : string,
    headerKeywords: string,
    headerDesc    : string
}

export interface ContentCaring {
    boarding    : string,
    grooming    : string,
    exercise    : string,
    feeding     : string,
    training    : string,
    treatment   : string
}

export interface SocialPost {
    social_post_id  : number,
    app_id          : number,
    link            : string,
    source_code     : string,
    name            : string,
    filtered        : boolean
}

export interface IFeed {
    feed_type       : string,
    id              : number,
    link            : string,
    source_code     : string,
    image_url       : string,
    title           : string,
    description     : string,
    date_published  : number,
    date_retrieved  : number
}
