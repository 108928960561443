import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
    contactEmail    : string,
    contactPhone    : string,
    contactAddress  : string,
    siteName        : string,
}

const Footer = ({
    contactEmail,
    contactPhone,
    contactAddress,
    siteName,
  }: Props) => {

    return (
        <>
            {/* Footer: Start */}
            <div className="container-fluid bg-light mt-5 py-5">
                <div className="container pt-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-uppercase border-start border-5 border-primary ps-3 mb-4">Get In Touch</h5>
                            <p className="mb-4">We'd love to hear from you! Contact us using info below, and we'll get back to you as soon as possible.</p>
                            <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2"></i>{contactAddress}</p>
                            <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i><a href='mailto:{contactEmail}'>{contactEmail}</a></p>
                            <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>{contactPhone}</p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-uppercase border-start border-5 border-primary ps-3 mb-4">Quick Links</h5>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-body mb-2" href="/#home"><i className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                <a className="text-body mb-2" href="/about"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                {/*
                                <a className="text-body mb-2" href="/"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                                */}
                                <a className="text-body" href="/contact"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-uppercase border-start border-5 border-primary ps-3 mb-4">Popular Links</h5>
                            <div className="d-flex flex-column justify-content-start">
                                <a className="text-body mb-2" href='https://www.akc.org/' target='_blank' rel='noreferrer noopener'><i className="bi bi-arrow-right text-primary me-2"></i>American Kennel Club</a>
                                <a className="text-body mb-2" href='https://www.thekennelclub.org.uk/' target='_blank' rel='noreferrer noopener'><i className="bi bi-arrow-right text-primary me-2"></i>The Kennel Club UK</a>
                                <a className="text-body mb-2" href='https://www.petfinder.com/' target='_blank' rel='noreferrer noopener'><i className="bi bi-arrow-right text-primary me-2"></i>Petfinder</a>                                
                                <a className="text-body mb-2" href='https://www.petsmart.com/dog-1/#page_name=flyout&category=dog' target='_blank' rel='noreferrer noopener'><i className="bi bi-arrow-right text-primary me-2"></i>Petsmart</a>
                                <a className="text-body mb-2" href='https://www.petco.com/shop/en/petcostore/category/dog/dog-health-and-wellness' target='_blank' rel='noreferrer noopener'><i className="bi bi-arrow-right text-primary me-2"></i>Petco</a>                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-uppercase border-start border-5 border-primary ps-3 mb-4">Newsletter</h5>
                            <form action="">
                                <div className="input-group">
                                    <input type="text" className="form-control p-3" placeholder="Your Email" />
                                    <button className="btn btn-primary">Sign Up</button>
                                </div>
                            </form>
                            {/*
                            <h6 className="text-uppercase mt-4 mb-3">Follow Us</h6>
                            <div className="d-flex">
                                <a className="btn btn-outline-primary btn-square me-2" href="/"><i className="bi bi-twitter"></i></a>
                                <a className="btn btn-outline-primary btn-square me-2" href="/"><i className="bi bi-facebook"></i></a>
                                <a className="btn btn-outline-primary btn-square me-2" href="/"><i className="bi bi-linkedin"></i></a>
                                <a className="btn btn-outline-primary btn-square" href="/"><i className="bi bi-instagram"></i></a>
                            </div>
                            */}
                        </div>
                        <div className="col-12 text-center text-body">
                            <Link to="/terms" className="text-body">Terms & Conditions</Link>
                            <span className="mx-1">|</span>
                            <Link to="/privacy" className="text-body">Privacy Policy</Link>
                            {/*}
                            <span className="mx-1">|</span>
                            <a className="text-body" href="/">Help</a>
                            <span className="mx-1">|</span>
                            <a className="text-body" href="/">FAQs</a>
                            */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-dark text-white-50 py-4">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-md-6 text-center text-md-start">
                            <p className="mb-md-0">&copy; <a className="text-white" href="/">{siteName}</a>. All Rights Reserved.</p>
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <p className="mb-0">Developed by <a className="text-white" href="https://instantapp.dev" target='_blank' rel='noreferrer noopener'>InstantApp</a></p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer: End */}
        </>  
    );    
};


const mapStateToProps = (state: any) => ({
    contactEmail    : state.homeReducer.contactEmail,
    contactPhone    : state.homeReducer.contactPhone,
    contactAddress  : state.homeReducer.contactAddress,
    siteName        : state.homeReducer.siteName,
});
  
export default connect(mapStateToProps)(Footer);

