export const LOAD_APP_CONFIG            = 'LOAD_APP_CONFIG';
export const LOAD_APP_CONFIG_SUCCESS    = 'LOAD_APP_CONFIG_SUCCESS';
export const LOAD_APP_CONFIG_FAILED     = 'LOAD_APP_CONFIG_FAILED';

export const CONTACT_US_VALIDATION_FAILED= 'CONTACT_US_VALIDATION_FAILED';
export const SEND_EMAIL_CONTACT_US      = 'SEND_EMAIL_CONTACT_US';
export const SEND_EMAIL_SUCCESS         = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILED          = 'SEND_EMAIL_FAILED';

export const LOAD_CONTENT_HOME          = 'LOAD_CONTENT_HOME';
export const LOAD_CONTENT_HOME_SUCCESS  = 'LOAD_CONTENT_HOME_SUCCESS';
export const LOAD_CONTENT_HOME_FAILED   = 'LOAD_CONTENT_HOME_FAILED';

export const LOAD_FEED                  = 'LOAD_FEED';
export const LOAD_FEED_SUCCESS          = 'LOAD_FEED_SUCCESS';
export const LOAD_FEED_FAILED           = 'LOAD_FEED_FAILED';

export const LOAD_SOCIAL_POST           = 'LOAD_SOCIAL_POST';
export const LOAD_SOCIAL_POST_SUCCESS   = 'LOAD_SOCIAL_POST_SUCCESS';
export const LOAD_SOCIAL_POST_FAILED    = 'LOAD_SOCIAL_POST_FAILED';

export const LOAD_LATEST_NEWS           = 'LOAD_LATEST_NEWS';
export const LOAD_LATEST_NEWS_SUCCESS   = 'LOAD_LATEST_NEWS_SUCCESS';
export const LOAD_LATEST_NEWS_FAILED    = 'LOAD_LATEST_NEWS_FAILED';

export const LOAD_LATEST_EVENTS         = 'LOAD_LATEST_EVENTS';
export const LOAD_LATEST_EVENTS_SUCCESS = 'LOAD_LATEST_EVENTS_SUCCESS';
export const LOAD_LATEST_EVENTS_FAILED  = 'LOAD_LATEST_EVENTS_FAILED';

export const LOAD_CONTENT_HOME_CARING_SUCCESS  = 'LOAD_CONTENT_HOME_CARING_SUCCESS';
export const LOAD_CONTENT_HOME_CARING_FAILED   = 'LOAD_CONTENT_HOME_CARING_FAILED';

// APP Config
export const loadAppConfig = (data: any) => ({
  type    : LOAD_APP_CONFIG,
  payload : data,
});

export const loadAppConfigSuccess = (data: any) => ({
  type    : LOAD_APP_CONFIG_SUCCESS,
  payload : data,
});

export const loadAppConfigFailed = (error: string) => ({
  type    : LOAD_APP_CONFIG_FAILED,
  payload : error,
});

// Content: Home
/*
export const loadContentHome = (data: any) => ({
  type    : LOAD_CONTENT_HOME,
  payload : data,
});
*/

export const loadContentHomeSuccess = (data: any) => ({
  type    : LOAD_CONTENT_HOME_SUCCESS,
  payload : data,
});

export const loadContentHomeFailed = (error: string) => ({
  type    : LOAD_CONTENT_HOME_FAILED,
  payload : error,
});

export const loadFeedSuccess = (data: any) => ({
  type    : LOAD_FEED_SUCCESS,
  payload : data,
});

export const loadFeedFailed = (error: string) => ({
  type    : LOAD_FEED_FAILED,
  payload : error,
});

export const loadSocialPostSuccess = (data: any) => ({
  type    : LOAD_SOCIAL_POST_SUCCESS,
  payload : data,
});

export const loadSocialPostFailed = (error: string) => ({
  type    : LOAD_SOCIAL_POST_FAILED,
  payload : error,
});

// Latest News
export const loadLatestNewsSuccess = (data: any) => ({
  type    : LOAD_LATEST_NEWS_SUCCESS,
  payload : data,
});

export const loadLatestNewsFailed = (error: string) => ({
  type    : LOAD_LATEST_NEWS_FAILED,
  payload : error,
});

// Latest Events
export const loadLatestEventsSuccess = (data: any) => ({
  type    : LOAD_LATEST_EVENTS_SUCCESS,
  payload : data,
});

export const loadLatestEventsFailed = (error: string) => ({
  type    : LOAD_LATEST_EVENTS_FAILED,
  payload : error,
});

// Content: Home - Caring
export const loadContentHomeCaringSuccess = (data: any) => ({
  type    : LOAD_CONTENT_HOME_CARING_SUCCESS,
  payload : data,
});

export const loadContentHomeCaringFailed = (error: string) => ({
  type    : LOAD_CONTENT_HOME_CARING_FAILED,
  payload : error,
});


// Send Email: Contact Us
export const sendEmailContactUs = (data: any) => ({
  type    : SEND_EMAIL_CONTACT_US,
  payload : data,
});

export const sendEmailSuccess = () => ({
  type    : SEND_EMAIL_SUCCESS,
});

export const sendEmailFailed = (error: string) => ({
  type    : SEND_EMAIL_FAILED,
  payload : error,
});

