import React, { Component } from 'react';
import { connect } from 'react-redux';

interface Props {
    contactEmail    : string,
    contactPhone    : string,
    contactAddress  : string,
}

const TopBar = ({
    contactEmail,
    contactPhone,
    contactAddress,
  }: Props) => {

    return (
        <>
            {/* Topbar: Start */}
            <div className="container-fluid border-bottom d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-lg-4 text-center py-2">
                        <div className="d-inline-flex align-items-center">
                            <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                            <div className="text-start">
                                <h6 className="text-uppercase mb-1">Our Office</h6>
                                <span>{contactAddress}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center border-start border-end py-2">
                        <div className="d-inline-flex align-items-center">
                            <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
                            <div className="text-start">
                                <h6 className="text-uppercase mb-1">Email Us</h6>
                                <a href='mailto:{contactEmail}'>{contactEmail}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center py-2">
                        <div className="d-inline-flex align-items-center">
                            <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                            <div className="text-start">
                                <h6 className="text-uppercase mb-1">Call Us</h6>
                                <span>{contactPhone}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Topbar: End */}        
        </>  
    );    
};


const mapStateToProps = (state: any) => ({
    contactEmail    : state.homeReducer.contactEmail,
    contactPhone    : state.homeReducer.contactPhone,
    contactAddress  : state.homeReducer.contactAddress,
});
  
export default connect(mapStateToProps)(TopBar);

