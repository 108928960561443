import React, { Component } from 'react';
import { connect } from 'react-redux';

const tenantKey = process.env.REACT_APP_TENANT_KEY;
const hostBase  = process.env.REACT_APP_HOST_BASE;
const breedName = process.env.REACT_APP_BREED_NAME;
const cdnUrl    = process.env.REACT_APP_CDN_URL;

const Hero = (props:any) => {


    const imgBGHero = cdnUrl+ '/img/hero.jpg';
    const styleHero = {
        backgroundImage : `url(${imgBGHero})`,
        marginTop           : "0px",
        backgroundSize      : "cover",
        backgroundRepeat    : "no-repeat",
        backgroundPosition  : "center",
    };

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <>
            {/* Hero: Start */}
            <div id='home' className="container-fluid bg-primary py-5 mb-5" style={styleHero}>
                <div className="container py-5">
                    <div className="row justify-content-start">
                        <div className="col-lg-8 text-center text-lg-start">
                            <h1 className="display-1 text-uppercase text-dark mb-lg-4">{props.hero.headline1}</h1>
                            <h1 className="text-uppercase text-white mb-lg-4">{props.hero.headline2}</h1>
                            <p className="fs-4 text-white mb-lg-4">
                                {props.hero.description}
                            </p>
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-start pt-5">
                                <a href="/about" className="btn btn-outline-light border-2 py-md-3 px-md-5 me-5">Read More</a>
                                {/* 
                                <button type="button" className="btn-play" data-bs-toggle="modal"
                                    data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                                    <span></span>
                                </button>
                                <h5 className="font-weight-normal text-white m-0 ms-4 d-none d-sm-block">Play Video</h5>
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Hero: End */}
        </>
    );
}

// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {    hero : state.homeReducer.content.hero,
    };
}

export default connect(mapStateToProps)(Hero);
