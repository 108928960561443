import React, { Component } from 'react';

import LayoutMaster from '../../layouts/LayoutMaster';

class PostsMain extends Component {

    ///////////////////////////
    // componentDidMount()
    ///////////////////////////
    componentDidMount() { 
        //console.log('PostsMain.componentDidMount()');
    } 

    ///////////////////////////
    // render()
    ///////////////////////////
    render() { 

        return (
            <LayoutMaster>
                this is posts page.
            </LayoutMaster>
        );
    }

}

export default PostsMain;
