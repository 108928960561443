import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutMaster from '../../layouts/LayoutMaster';

const cdnUrl      = process.env.REACT_APP_CDN_URL;  
const companyName = process.env.REACT_APP_COMPANY_NAME;
const imgTerms    =  'img/offer.jpg';
const styleTerms  = {
    backgroundImage     : `url(${imgTerms})`,
    marginTop           : "0px",
    backgroundSize      : "cover",
    backgroundRepeat    : "no-repeat",
    backgroundPosition  : "center",
};

const terms = [
    {
      name: 'Acceptance of Terms',
      description:
        'By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our website.',
      //icon: CloudArrowUpIcon,
    },
    {
      name: 'Intellectual Property',
      description:
        'All content on this website, including but not limited to text, images, videos, graphics, logos, and trademarks, is the property of '+ companyName+ ' or its licensors or its original owners and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, distribute, or modify any content from this website without prior written consent from '+ companyName+ '.',
      //icon: LockClosedIcon,
    },
    {
      name: 'User Conduct',
      description:
        'When using our website, you agree to: 1. Use the website only for lawful purposes and in compliance with these Terms and Conditions. 2. Not engage in any activity that could harm, damage, or interfere with the functioning of the website. 3. Not attempt to gain unauthorized access to any part of the website or its systems.',
      //icon: ArrowPathIcon,
    },
    {
      name: 'Listings and Adoption',
      description:
        'Our website may feature listings of [Shih Tzu] available for adoption. While we strive to provide accurate and up-to-date information, we cannot guarantee the availability or accuracy of any listings. Adoption inquiries and arrangements should be made directly with the listed adoption organizations or individuals.',
      //icon: FingerPrintIcon,
    },
    {
        name: 'Limitation of Liability',
        description:
          'Under no circumstances shall '+ companyName+ ' be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of this website or the information provided on this website.',
        //icon: FingerPrintIcon,
      },
      {
        name: 'Changes to Terms',
        description:
          'We reserve the right to modify these Terms and Conditions at any time without prior notice. By continuing to use our website after such modifications, you agree to be bound by the revised Terms and Conditions.',
        //icon: FingerPrintIcon,
      },
    ]

  {/*  
  
*/}

  
interface Props {
    breedName   : string,
    contactEmail: string
}

const TermsMain = (props:Props) => {

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <LayoutMaster>

            {/* TERMS: Top */}
            <div className="container-fluid bg-offer" style={styleTerms}>
                <div className="container py-5">
                    <div className="row gx-5 justify-content-start">
                        <div className="col-lg-7">
                            <div className="border-start border-5 border-dark ps-5 mb-5">
                                <h6 className="text-white text-uppercase">Terms and Conditions</h6>
                                <h1 className="display-5 text-white mb-0">
                                    These govern your use of our website, including contents, features, and services. 
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="tw-bg-white sm:tw-py-32">
                <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">

                    {/* DETAILS */}
                    <div className="tw-mx-auto tw-max-w-5xl lg:tw-text-center bg-light tw-py-10 tw-px-5">
                        <p>
                            By accessing or using our website, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, 
                            please refrain from using our website.
                        </p>
                    </div>

                    {/* FEATURES */}
                    <div className="tw-mx-auto tw-mt-16 tw-max-w-3xl sm:tw-mt-20 lg:tw-mt-24 lg:tw-max-w-4xl">
                        <h5></h5>
                        <dl className="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-y-16">
                            {terms.map((item) => (
                            <div key={item.name} className="tw-relative tw-pl-16">
                                <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">
                                <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-yellow-800">
                                    {/*
                                    <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                    */}
                                </div>
                                {item.name}
                                </dt>
                                <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{item.description}</dd>
                            </div>
                            ))}
                        </dl>
                    </div>

                    {/* MORE */}
                    <div className="tw-mx-auto tw-max-w-5xl lg:tw-text-center bg-light tw-py-10 tw-px-5">
                        <p>
                            If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href='mailto:{props.contactEmail}'>{props.contactEmail}</a>.
                        </p>
                    </div>


                </div>
            </div>
            
        </LayoutMaster>
    );

}

const mapStateToProps = (state: any) => ({
    breedName   : state.homeReducer.breedName,
    contactEmail: state.homeReducer.contactEmail
});
  
export default connect(mapStateToProps)(TermsMain);


