import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Caring = (props:any) => {

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <>
            {/* Caring: Start */}
            <div id='caring' className="container-fluid py-5">
                <div className="container">
                    <div className="border-start border-5 border-primary ps-5 mb-5" style={{maxWidth: '600px'}}>
                        <h6 className="text-primary text-uppercase">Ways to take proper care</h6>
                        <h1 className="display-5 text-uppercase mb-0">Caring for them</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-md-6">
                            <div className="service-item bg-light d-flex p-4">
                                <i className="flaticon-house display-1 text-primary me-4"></i>
                                <div>
                                    <h5 className="text-uppercase mb-3">Boarding</h5>
                                    <p>{props.caring.boarding}</p>
                                    <Link className="text-primary text-uppercase" to={`/article/boarding`}>Read More<i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-item bg-light d-flex p-4">
                                <i className="flaticon-food display-1 text-primary me-4"></i>
                                <div>
                                    <h5 className="text-uppercase mb-3">Feeding</h5>
                                    <p>{props.caring.feeding}</p>
                                    <Link className="text-primary text-uppercase" to={`/article/feeding`}>Read More<i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-item bg-light d-flex p-4">
                                <i className="flaticon-grooming display-1 text-primary me-4"></i>
                                <div>
                                    <h5 className="text-uppercase mb-3">Grooming</h5>
                                    <p>{props.caring.grooming}</p>
                                    <Link className="text-primary text-uppercase" to={`/article/grooming`}>Read More<i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-item bg-light d-flex p-4">
                                <i className="fa-solid fa-dog display-1 text-primary me-4"></i>
                                <div>
                                    <h5 className="text-uppercase mb-3">Training</h5>
                                    <p>{props.caring.training}</p>
                                    <Link className="text-primary text-uppercase" to={`/article/training`}>Read More<i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-item bg-light d-flex p-4">
                                <i className="flaticon-dog display-1 text-primary me-4"></i>
                                <div>
                                    <h5 className="text-uppercase mb-3">Exercise</h5>
                                    <p>{props.caring.exercise}</p>
                                    <Link className="text-primary text-uppercase" to={`/article/exercise`}>Read More<i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service-item bg-light d-flex p-4">
                                <i className="flaticon-vaccine display-1 text-primary me-4"></i>
                                <div>
                                    <h5 className="text-uppercase mb-3">Treatment</h5>
                                    <p>{props.caring.treatment}</p>
                                    <Link className="text-primary text-uppercase" to={`/article/treatment`}>Read More<i className="bi bi-chevron-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Caring: End */}
        </>
    );
}

// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {    caring : state.homeReducer.content.caring,
    };
}

export default connect(mapStateToProps)(Caring);
