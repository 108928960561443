import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const siteName = process.env.REACT_APP_TENANT_SITE_NAME;
const cdnUrl    = process.env.REACT_APP_CDN_URL;

const NavBar = (props:any) => {

    const imgLogo = cdnUrl+ '/img/logo_header.png';

    return (
        <>
            {/* Navbar: Start */}
            <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm py-3 py-lg-0 px-3 px-lg-0">
                <a href="/" className="navbar-brand ms-lg-5">
                    {/*
                    <h1 className="m-0 text-uppercase text-dark"><i className="bi bi-shop fs-1 text-primary me-3"></i>{siteName}</h1>
                    */}
                    <img className="img-fluid mb-4" src={imgLogo} alt={siteName} />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <Link to="/#home" className="nav-item nav-link">Home</Link>
                        <a href="/#news" className="nav-item nav-link">News</a>
                        <a href="/#social" className="nav-item nav-link">Social</a>
                        <a href="/#caring" className="nav-item nav-link">Caring</a>
                        <Link to="/about" className="nav-item nav-link">About</Link>
                        {/*
                        <div className="nav-item dropdown">
                            <a href="/" className="nav-link dropdown-toggle active" data-bs-toggle="dropdown">Blogs</a>
                            <div className="dropdown-menu m-0">
                                <Link to="/blog/article" className="dropdown-item">Article #1</Link>
                                <Link to="/blog/article" className="dropdown-item">Article #2</Link>
                                <Link to="/blog/article" className="dropdown-item">Article #3</Link>
                            </div>
                        </div>
                        */}
                        <Link to="/contact" className="nav-item nav-link nav-contact bg-primary text-white px-5 ms-lg-5">Contact <i className="bi bi-arrow-right"></i></Link>
                    </div>
                </div>
            </nav>
            {/* Navbar: End */}
        </>
    );
}

// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {
    };
}

export default connect(mapStateToProps)(NavBar);

