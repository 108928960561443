import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import {Helmet} from "react-helmet";
import { LOAD_APP_CONFIG, LOAD_CONTENT_HOME } from '../store/home/actions';

interface Props {
    titlePage       : string,
    headerKeywords  : string,
    headerDesc      : string,
}
  
const Title = ({
    titlePage,
    headerKeywords,
    headerDesc,
  }: Props) => {

    const dispatch = useDispatch();

    ///////////////////////////
    // useEffect()
    ///////////////////////////
    useEffect(() => {
        var data = {
            appName : process.env.REACT_APP_APP_NAME,
            appCdn  : process.env.REACT_APP_CDN_URL,
        };
        dispatch({
            type    : LOAD_APP_CONFIG,
            payload : data,
        });  
        dispatch({
            type    : LOAD_CONTENT_HOME,
            payload : data,
        });  
    });    

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{titlePage}</title>
            <meta name="keywords" content={headerKeywords} />
            <meta name="description" content={headerDesc} />
        </Helmet>  
    );    
};


const mapStateToProps = (state: any) => ({
    titlePage       : state.homeReducer.titlePage,
    headerKeywords  : state.homeReducer.headerKeywords,
    headerDesc      : state.homeReducer.headerDesc,
});
  
export default connect(mapStateToProps)(Title);
