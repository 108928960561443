import { combineReducers } from 'redux';
import homeReducer   from './home/reducer';
import artReducer   from './article/reducer';
import newsReducer   from './news/reducer';
import blogReducer   from './blog/reducer';

export default combineReducers({
  homeReducer : homeReducer,
  artReducer  : artReducer,
  newsReducer : newsReducer,
  blogReducer : blogReducer,
});
