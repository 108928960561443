import About from '../../pages/home/Feed';
import {  LOAD_APP_CONFIG_SUCCESS,
          LOAD_APP_CONFIG_FAILED,
          LOAD_CONTENT_HOME_SUCCESS,
          LOAD_FEED_SUCCESS,
          LOAD_FEED_FAILED,
          LOAD_SOCIAL_POST_SUCCESS,
          LOAD_SOCIAL_POST_FAILED,
          LOAD_LATEST_NEWS_SUCCESS,
          LOAD_LATEST_NEWS_FAILED,
          LOAD_LATEST_EVENTS_SUCCESS,
          LOAD_LATEST_EVENTS_FAILED,
          CONTACT_US_VALIDATION_FAILED,
          SEND_EMAIL_CONTACT_US, 
          SEND_EMAIL_SUCCESS, 
          SEND_EMAIL_FAILED } from './actions';

       
const initialState = {
  loading       : false,
  appName       : '',
  siteName      : '',
  contactEmail  : '',
  contactPhone  : '',
  contactAddress: '',
  breedName     : '',
  headerKeywords: '',
  headerDesc    : '',
  titlePage     : '',
  message       : '',   
  messageType   : '',
  content       : {
    hero         : {
      headline1   : '',
      headline2   : '',
      description : '',
    },
    about         : {
      mission     : '',
      vision      : '',
    },
    caring        : {
      boarding    : '',
      grooming    : '',
      exercise    : '',
      feeding     : '',
      training    : '',
      treatment   : '',  
    },  
  },
  feed            : [],
  socialPosts     : [],
  latestNews      : [],
  latestEvents    : [],
  alSocialPostX  : [
    {
        social_post_id  : 1,
        app_id          : 8,
        name        : 'Arashi Software',
        description : 'Japan-based Software Development Company that features uncluttered designs that focus on content which what matters most.',
        img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/arashisoftware.jpg',
        url         : 'https://portfolio.instantapp.dev/arashisoftware',
        source_code     : 'YT',
        category    : ['all', 'web'],
        filtered    : true,
    },
    {
        social_post_id  : 2,
        app_id          : 8,
        name        : 'Bratt / Jolie Wedding',
        description : 'Wedding website of wonderful couple Bratt and Jolie who are trying knot at the beautiful Central Park in New York, New York.',
        img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/brattjoliewedding.jpg',
        url         : 'https://portfolio.instantapp.dev/brattjoliewedding',
        source_code     : 'TT',
        category    : ['all', 'web', 'mar'],
        filtered    : true,
    },
    {
        social_post_id  : 3,
        app_id          : 8,
        name        : 'Expression Studio',
        description : 'Expression Studio is an art studio that has been a beacon for artists and art enthusiasts alike. It is where creativity knows no bounds.',
        img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/expression.jpg',
        url         : 'https://portfolio.instantapp.dev/expression',
        source_code     : 'IG',
        category    : ['all',  'web', 'mar'],
        filtered    : true,
    },
    {
        social_post_id  : 4,
        app_id          : 8,
        name        : 'Fresh Bean Cafe',
        description : 'Fresh Bean Cafe is is more than just a place to grab a cup. It is a haven for coffee enthusiasts and a hub for the community.',
        img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/freshbeancafe.jpg',
        url         : 'https://portfolio.instantapp.dev/freshbean',
        source_code     : 'FB',
        category    : ['all', 'web', 'mob'],
        filtered    : true,
    },
    {
        social_post_id  : 5,
        app_id          : 8,
        name        : 'Futura Architecture',
        description : 'It is an architecture service company who is passionate in creating spaces that inspire, engage and endure... where innovative design meets timeless architecture.',            
        img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/futura.jpg',
        url         : 'https://portfolio.instantapp.dev/futura',
        source_code     : 'PI',
        category    : ['all', 'des'],
        filtered    : true,
    },
    {
        social_post_id  : 6,
        app_id          : 8,
        name        : 'Olive Kitchen',
        description : 'This high-end restaurant creates a dining experience that transports patrons through the tantalizing tales of American cuisine.',
        img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/olivekitchen.jpg',
        url         : 'https://portfolio.instantapp.dev/olivekitchen',
        source_code     : 'TW',
        category    : ['all', 'web', 'mob'],
        filtered    : true,
    },
    {
      social_post_id  : 7,
      app_id          : 8,
      name        : 'Prestige Watch',
      description : 'Nestled in the heart of New York, this boutique is a haven for connoisseurs of luxury watches seeking the epitome of horological craftsmanship.',
      img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/prestige.jpg',
      url         : 'https://portfolio.instantapp.dev/prestigewatch',
      source_code     : 'IG',
      category    : ['all', 'web', 'mar'],
      filtered    : true,
    },
    {
      social_post_id  : 8,
      app_id          : 8,
      name        : 'WOW Travel',
      description : 'WOW Travel curates the perfect travel experience for its customers.',
      img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/wowtravel.jpg',
      url         : 'https://portfolio.instantapp.dev/wowtravel',
      source_code     : 'FB',
      category    : ['all', 'web', 'mob', 'mar'],
      filtered    : true,
    },
    {
      social_post_id  : 9,
      app_id          : 8,
      name        : 'McArthur Hosting',
      description : 'Highly-trusted and highly reliable hosting service provider based in Silicon Valley.',          
      img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/mcarthurhosting.jpg',
      url         : 'https://mcarthurhosting.com',
      source_code     : 'YT',
      category    : ['all', 'web'],
      filtered    : true,
    },
    {
      social_post_id  : 10,
      app_id          : 8,
      name        : 'McArthur Software',
      description : 'McArthur Software delivers business-aligned engineering services at a flexible scale for bespoke applications, products and platforms.',
      img         : 'https://portfolio.instantapp.dev/asset/images/portfolio/mcarthursoftware.jpg',
      url         : 'https://mcarthursoftware.com',
      source_code     : 'TT',
      category    : ['all', 'web', 'mob'],
      filtered    : true,
    },
],

}


//////////////////////////
// Reducer
//////////////////////////
function Reducer(state = initialState, action: any) {
  
  switch (action.type) {

    case LOAD_APP_CONFIG_SUCCESS:
      /*
      console.log('home.reducer');
      console.log('action.payload');
      console.log(action.payload);
      console.log('action.payload.appName: '+ action.payload.appName);
      */
      return { 
        ...state, 
        siteName      : action.payload.siteName,
        breedName     : action.payload.breedName,
        headerKeywords: action.payload.header.keywords,
        headerDesc    : action.payload.header.description,
        titlePage     : 'All about '+ action.payload.breedName,
        contactEmail  : action.payload.contact.email,
        contactPhone  : action.payload.contact.phone,
        contactAddress: action.payload.contact.address,
      };

    case LOAD_CONTENT_HOME_SUCCESS:
      //console.log('LOAD_CONTENT_HOME_SUCCESS');
      //console.log(action.payload);
      return { 
        ...state, 
        content : action.payload,
        hero    : action.payload.hero,
        about   : action.payload.about,
        caring  : action.payload.caring,
      };
  
    case LOAD_FEED_SUCCESS:
        //console.log('LOAD_FEED_SUCCESS');
        //console.log(action.payload);
        return { 
          ...state, 
          feed : action.payload,
        };

    case LOAD_FEED_FAILED:
      return { 
        ...state, 
        messageType : 'error',   
        message     : 'An issue was encountered while loading feeds.',
      };
  
    case LOAD_SOCIAL_POST_SUCCESS:
      //console.log('LOAD_SOCIAL_POST_SUCCESS');
      //console.log(action.payload);
      return { 
        ...state, 
        socialPosts : action.payload,
      };

    case LOAD_SOCIAL_POST_FAILED:
        return { 
          ...state, 
          messageType : 'error',   
          message     : 'An issue was encountered while loading social posts.',
        };

    case LOAD_LATEST_NEWS_SUCCESS:
      //console.log('LOAD_LATEST_NEWS_SUCCESS');
      //console.log(action.payload);
      return { 
        ...state, 
        latestNews : action.payload,
      };

    case LOAD_LATEST_NEWS_FAILED:
      return { 
        ...state, 
        messageType : 'error',   
        message     : 'An issue was encountered while retrieving latest news.',
      };

    case LOAD_LATEST_EVENTS_SUCCESS:
      //console.log('LOAD_LATEST_EVENTS_SUCCESS');
      //console.log(action.payload);
      return { 
        ...state, 
        latestEvents : action.payload,
      };

    case LOAD_LATEST_EVENTS_FAILED:
      return { 
        ...state, 
        messageType : 'error',   
        message     : 'An issue was encountered while retrieving latest events.',
      };
        
    case CONTACT_US_VALIDATION_FAILED:
      return { 
        ...state, 
        messageType : 'error',   
        message     : 'Please enter your name, email and message.',
      };

    case SEND_EMAIL_CONTACT_US:
      return { 
        ...state, 
        loading: true, 
      };

    case SEND_EMAIL_SUCCESS:
      return { 
        ...state, 
        messageType : 'success',   
        message     : 'Thank you for your message. We\'ll get back to you soon! Promise.',
      };

    case SEND_EMAIL_FAILED:
      return { 
        ...state, 
        loading: false, 
        messageType : 'error',   
        message     : 'Sorry, an issue was encountered while processing your message.',
      };
      
    default:
      return state;
  }
}

export default Reducer;
