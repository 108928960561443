import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  LOAD_ARTICLE, loadArticleSuccess, loadArticleFailed } from './actions';

const appCdn = process.env.REACT_APP_CDN_URL;

//////////////////////////
// loadArticle()
//////////////////////////
function* loadArticle(action: any) {
  try {
    //console.log('home/saga::loadArticle()');
    //console.log('appCdn');
    //console.log(appCdn);

    var slug = action.payload.slug;
    var article =  appCdn+ '/content/article/'+ slug+ '.json';
    //console.log('article : '+ article);
    const { data } = yield call(axios.get, article, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(loadArticleSuccess(data));    
  } catch (error) {
    console.log('saga.loadArticle() error');
    console.log(error);
    yield put(loadArticleFailed('An issue was encountered while loading the article.'));
  }
}


//////////////////////////
// rootSaga()
//////////////////////////
export default function* rootSaga() {
  yield all([takeLatest(LOAD_ARTICLE, loadArticle)]);
}
