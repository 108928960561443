import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import ReactHtmlParser, { processNodes, convertNodeToElement } from 'react-html-parser';

import LayoutMaster from '../../layouts/LayoutMaster';

import { LOAD_ARTICLE } from '../../store/article/actions';

const ArticleMain = (props:any) => {

    const center = {
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingTop: '20px'
        //height: '100vh'
      };

    const dispatch = useDispatch();

    // useEffect()
    useEffect(() => {
        try {
            /*
            console.log('useEffect()');
            console.log('slug');
            console.log(props.match.params.slug);
            */
            var data = {
                slug : props.match.params.slug
            };
            dispatch({
                type    : LOAD_ARTICLE,
                payload : data,
            });          
        } catch (error) {
          console.log(error);
        }    
    }, []);
    
    return (
        <LayoutMaster>
            <div style={{padding: '10px 25px 10px 25px'}}>
                <h1 style={center}>{props.article.title}</h1>
                <div>                
                    { ReactHtmlParser(props.article.content) }
                </div>
            </div>
        </LayoutMaster>
    );

}


// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {    article : state.artReducer.article,
    };
}

export default connect(mapStateToProps)(ArticleMain);
