import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {  LOAD_BLOG, loadBlogSuccess, loadBlogFailed } from './actions';

const apiUrl = process.env.REACT_APP_API_URL;

//////////////////////////
// loadBlog()
//////////////////////////
function* loadBlog(action: any) {
  try {
    //console.log('home/saga::loadBlog()');
    var appCdn    = action.payload.appCdn;

    //=temp only
    appCdn = 'https://cdn.instantapp.dev/shihtzu';

    var slug = action.payload.slug;
    var blog =  appCdn+ '/content/article/'+ slug+ '.json';
    //console.log('article : '+ article);
    const { data } = yield call(axios.get, blog, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(loadBlogSuccess(data));    
  } catch (error) {
    console.log('saga.loadBlog() error');
    console.log(error);
    yield put(loadBlogFailed('An issue was encountered while loading the blog.'));
  }
}


//////////////////////////
// rootSaga()
//////////////////////////
export default function* rootSaga() {
  yield all([takeLatest(LOAD_BLOG, loadBlog)]);
}
