import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import LayoutMaster from '../../layouts/LayoutMaster';
import Hero from './Hero';
import Social from './Social';
import Feed from './Feed';
import Caring from './Caring';
import Products from './Products';
import SpecialOffer from './SpecialOffer';
import Blog from './Blog';

import { LOAD_FEED, LOAD_SOCIAL_POST, LOAD_LATEST_NEWS, LOAD_LATEST_EVENTS } from '../../store/home/actions';

const hostBase  = process.env.REACT_APP_HOST_BASE;
const tenantKey = process.env.REACT_APP_TENANT_KEY;
const breedName = process.env.REACT_APP_BREED_NAME;

const HomeMain = (props:any) => {

    const dispatch = useDispatch();

    ///////////////////////
    // useEffect()
    ///////////////////////
    useEffect(() => {

        console.log('HomeMain.useEffect()');

        var data = {
            app_id : process.env.REACT_APP_APP_ID,
        };
        dispatch({
            type    : LOAD_FEED,
            payload : data,
        });  
        dispatch({
            type    : LOAD_SOCIAL_POST,
            payload : data,
        });  
        dispatch({
            type    : LOAD_LATEST_NEWS,
            payload : data,
        });  
        dispatch({
            type    : LOAD_LATEST_EVENTS,
            payload : data,
        });  

    }, []);
  

    return (
        <LayoutMaster>
            <Hero />
            <Feed tenantKey={`${tenantKey}`} breedName={`${breedName}`} />
            <Caring />
            {/*
            <Social />
            <Products />
            <SpecialOffer />
            <Blog />
            */}
        </LayoutMaster>
    );

}

// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {
    };
}

export default connect(mapStateToProps)(HomeMain);
