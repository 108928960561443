import React, { Component, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CONTACT_US_VALIDATION_FAILED, SEND_EMAIL_CONTACT_US } from '../../store/home/actions';
import Message from '../../components/Message';

import LayoutMaster from '../../layouts/LayoutMaster';

interface Props {
    message     : string,
    messageType : string
}

const ContactMain: React.FC<Props> = (props) => {    

    const dispatch = useDispatch();

    //const { message, messageType } = useSelector(state => state.homeReducer);

    const [inputName, setInputName]         = useState('');
    const [inputEmail, setInputEmail]       = useState('');
    const [inputMessage, setInputMessage]   = useState('');

    const handleChangeName = (event:any) => {
        setInputName(event.target.value);
    };
    const handleChangeEmail = (event:any) => {
        setInputEmail(event.target.value);
    };
    const handleChangeMessage = (event:any) => {
        setInputMessage(event.target.value);
    };

    //////////////////////
    // handleContactUs()
    //////////////////////
    const handleContactUs = () => {
        console.log('handleContactUs()');

        if (inputName == null || inputName == '' ||
            inputEmail == null || inputEmail == '' ||
            inputMessage == null || inputMessage == '') {
            dispatch({
                type    : CONTACT_US_VALIDATION_FAILED,
                payload : {},
            });  
            return;
        };

        var data = {
            appName : process.env.REACT_APP_APP_NAME,
            name    : inputName,
            email   : inputEmail,
            message : inputMessage,
        };
        
        dispatch({
            type    : SEND_EMAIL_CONTACT_US,
            payload : data,
        });  
    };

    const imgContact = 'https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80';
    const styleContact = {
        backgroundImage     : `url(${imgContact})`,
        marginTop           : "0px",
        backgroundSize      : "cover",
        backgroundRepeat    : "no-repeat",
        backgroundPosition  : "center",
        minHeight           : '500px',
    };

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <LayoutMaster>
            <div className="tw-bg-white tw-grid tw-grid-cols-2">
                <div className="tw-mx-auto tw-max-w-xl lg:tw-mx-0 lg:tw-max-w-lg">
                    <h2 className="tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900">Let's connect!</h2>
                    <p className="tw-mt-2 tw-text-lg tw-leading-8 tw-text-gray-600">
                        Have questions about your furry friend? Want to share your doggie tales with us? We'd love to hear from you! 
                        Feel free to reach out using the form below.
                    </p>
                    <div className="row">
                        <Message type={props.messageType} text={props.message} />
                    </div>

                    <div className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-6 sm:tw-grid-cols-2">
                        <div>
                            <label htmlFor="first-name" className="tw-block tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
                            Name
                            </label>
                            <div className="tw-mt-2.5">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                value={inputName} onChange={handleChangeName}
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-3.5 tw-py-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:tw-col-span-2">
                        <label htmlFor="email" className="tw-block tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
                        Email
                        </label>
                        <div className="tw-mt-2.5">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={inputEmail} onChange={handleChangeEmail} 
                            className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-3.5 tw-py-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"
                        />
                        </div>
                    </div>
                    <div className="sm:tw-col-span-2">
                        <div className="tw-flex tw-justify-between tw-text-sm tw-leading-6">
                            <label htmlFor="message" className="tw-block tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
                                How can we help you?
                            </label>
                            <p id="message-description" className="tw-text-gray-400">
                                Max 500 characters
                            </p>
                        </div>
                        <div className="tw-mt-2.5">
                            <textarea
                                id="message"
                                name="message"
                                rows={7}
                                required={true}
                                value={inputMessage} 
                                onChange={handleChangeMessage} 
                                aria-describedby="message-description"
                                className="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-3.5 tw-py-2 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-600 sm:tw-text-sm sm:tw-leading-6"                                    
                            />
                            <button
                                type="submit"
                                className="tw-rounded-md tw-bg-indigo-600 tw-px-3.5 tw-py-2.5 tw-text-center tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                style={{marginTop: '20px'}}
                                onClick={handleContactUs}>
                                Send message
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
                <div style={styleContact}>
                </div>
            </div>
        </LayoutMaster>
    );

}

const mapStateToProps = (state: any) => ({
    message     : state.homeReducer.message,
    messageType : state.homeReducer.messageType,
});
  
export default connect(mapStateToProps)(ContactMain);
