import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Title from './Title';
import TopBar from './TopBar';
import NavBar from './NavBar';

export default () => (
    <>
        <Title />
        <TopBar />
        <NavBar /> 
        <ToastContainer />
    </>
);
