import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LatestNews from './LatestNews';
import Moment from 'react-moment';
import { TikTokEmbed ,YouTubeEmbed, InstagramEmbed, PinterestEmbed, TwitterEmbed } from 'react-social-media-embed';

import { IFeed, FeedType, SourceCode } from '../../types';

//////////////////////////////////////////////
// SCREEN DIMENSION
//////////////////////////////////////////////
// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets.
// 769px — 1024px: Small screens, laptops.
//////////////////////////////////////////////


////////////////////////////
// Interface
////////////////////////////
interface Props {
    tenantKey : string,
    breedName : string
}

const styleList = {
    listStyleType: 'none'
};

const cdnUrl    = process.env.REACT_APP_CDN_URL;

const Feed = (props:any) => {

    const imgAbout = cdnUrl+ '/img/about.jpg';
    const styleLatest = {
        backgroundImage     : `url(${imgAbout})`,
        marginTop           : "0px",
        backgroundSize      : "cover",
        backgroundRepeat    : "no-repeat",
        backgroundPosition  : "center",
        minHeight           : '500px',
    };

    const [width, setWidth]             = useState(0);
    const [widthYT, setWidthYT]         = useState(0);
    const [heightYT, setHeightYT]       = useState(0);
    const [activeTab, setActiveTab]     = useState('news');
    const [expandNews, setExpandNews]   = useState(false);
    const [expandNewsLabel, setExpandNewsLabel] = useState('Show more...');

    //////////////////////
    // useEffect()
    //////////////////////
    useEffect(() => {

        function handleResize() {
            //console.log('handleResize() width:'+ window.innerWidth);
            //setWidth(window.innerWidth)
            // laptop, etc
            if (window.innerWidth >= 769) {
                setWidthYT(640);
                setHeightYT(360);
            // tablet
            } else if (window.innerWidth >= 481) {
                setWidthYT(480);
                setHeightYT(270);
            // mobile
            } else {
                setWidthYT(200);
                setHeightYT(328);
            }
        }        
        window.addEventListener("resize", handleResize)        
        handleResize();
        return () => { 
          window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])

    //////////////////////
    // toggleExpandNews()
    //////////////////////
    const toggleExpandNews = () => {
        console.log('toggleExpandNews()');
        setExpandNews(!expandNews);
        if (expandNews) {
            setExpandNewsLabel('Show more...');
        } else {
            setExpandNewsLabel('Show less...');
        }
    };

    return (
        <>
        {props.feed.map((feedObj:IFeed) => (
            <>
            {/* NEWS */}
            {feedObj.feed_type === FeedType.News ? (
                <div key={`feed_${feedObj.id}`} className="tw-p-4 sm:tw-p-6">
                    <div className="tw-flex tw-items-center sm:tw-items-start">
                        <div className="tw-h-32 tw-w-32 tw-flex-shrink-0 tw-overflow-hidden tw-rounded-lg tw-bg-gray-200 sm:tw-h-32 sm:tw-w-32">
                            {feedObj.image_url != null ? (
                                <a href={feedObj.link} target='_blank' rel='noreferrer noopener'>
                                    <img src={feedObj.image_url} alt={feedObj.title} title={feedObj.title} className="tw-h-full tw-w-full tw-object-cover tw-object-center" />
                                </a>
                            ) : undefined }
                        </div>
                        <div className="tw-ml-6 tw-flex-1 tw-text-sm">
                            <div className="tw-font-normal sm:tw-flex sm:tw-justify-between">
                                <Link className="tw-text-gray-900" to={`/news/${feedObj.id}`}  target='_blank' rel='noreferrer noopener'>
                                    {feedObj.title} 
                                    <i className='tw-text-xs'>(
                                        <Moment interval={30000} fromNow>{feedObj.date_retrieved}</Moment>
                                    </i>
                                    )
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>    
            ) : undefined }

            {/* SOCIAL POST */}
            {feedObj.feed_type === FeedType.SocialPost ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-my-5">
                {feedObj.source_code === SourceCode.Instagram ? (
                    <InstagramEmbed url={feedObj.link} width={328} />
                ) : undefined }
                {feedObj.source_code === SourceCode.Pinterest ? (
                    <PinterestEmbed  url={feedObj.link} width={345} height={467} />
                ) : undefined } 
                {feedObj.source_code === SourceCode.TikTok ? (
                    <TikTokEmbed url={feedObj.link} width={325} />
                ) : undefined }
                {feedObj.source_code === SourceCode.Twitter ? (
                    <TwitterEmbed url={feedObj.link} width={325} />
                ) : undefined }
                {feedObj.source_code === SourceCode.YouTube ? (
                    <YouTubeEmbed url={feedObj.link} width={widthYT} height={heightYT} />
                ) : undefined }
            </div>
        ) : undefined }
            </>
        ))}

        </>
    )

}

// mapStateToProps()
const mapStateToProps = (state: any) => {
    return {
        latestNews  : state.homeReducer.latestNews,
        latestEvents: state.homeReducer.latestEvents,
        socialPosts : state.homeReducer.socialPosts,
        feed : state.homeReducer.feed,
    };
}

export default connect(mapStateToProps)(Feed);
