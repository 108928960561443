import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutMaster from '../../layouts/LayoutMaster';

//import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline';

const features = [
    {
      name: 'Latest News and Updates',
      description:
        'Stay informed about the latest developments, trends, and stories surrounding '+ process.env.REACT_APP_BREED_NAME+ '. Whether it\'s health tips, grooming advice, or breed-specific news, we\'ve got you covered.',
      //icon: CloudArrowUpIcon,
    },
    {
      name: 'Entertaining Videos',
      description:
        'Dive into a treasure trove of heartwarming and entertaining videos featuring our favorite furry friends. From funny antics to heart-melting moments, there\'s never a dull moment with '+ process.env.REACT_APP_BREED_NAME+ 's around!',
      //icon: LockClosedIcon,
    },
    {
      name: 'Exciting Events',
      description:
        'Discover upcoming events, shows, and gatherings dedicated to '+ process.env.REACT_APP_BREED_NAME+ 's. Whether you\'re a seasoned enthusiast or a newcomer to the breed, these events offer fantastic opportunities to connect with fellow admirers and celebrate everything '+ process.env.REACT_APP_BREED_NAME+ '.',
      //icon: ArrowPathIcon,
    },
    {
      name: 'Adoption Listings',
      description:
        'Find your perfect match among our curated listings of '+ process.env.REACT_APP_BREED_NAME+ 's available for adoption. Giving a forever home to a '+ process.env.REACT_APP_BREED_NAME+ ' in need is not only fulfilling but also a wonderful way to enrich your life with unconditional love and companionship.',
      //icon: FingerPrintIcon,
    },
  ]

  const cdnUrl      = process.env.REACT_APP_CDN_URL;  
  const imgOffer    = cdnUrl+ '/img/offer.jpg';
  const styleAbout  = {
      backgroundImage     : `url(${imgOffer})`,
      marginTop           : "0px",
      backgroundSize      : "cover",
      backgroundRepeat    : "no-repeat",
      backgroundPosition  : "center",
  };

  interface Props {
    breedName  : string,
}

const AboutMain = (props:Props) => {

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <LayoutMaster>

            {/* ABOUT: Top */}
            <div className="container-fluid bg-offer" style={styleAbout}>
                <div className="container py-5">
                    <div className="row gx-5 justify-content-start">
                        <div className="col-lg-7">
                            <div className="border-start border-5 border-dark ps-5 mb-5">
                                <h6 className="text-white text-uppercase">About Us</h6>
                                <h1 className="display-5 text-white mb-0">
                                    We are the ultimate destination for {props.breedName} enthusiasts!
                                </h1>
                            </div>
                            <p className="text-white mb-4">                                
                                We are passionate about everything related to {props.breedName} - those adorable, affectionate, and 
                                loyal companions that steal our hearts with their charming personalities and irresistible cuteness.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="tw-bg-white sm:tw-py-32">
                <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">

                    {/* Mission */}
                    <div className="tw-mx-auto tw-max-w-5xl lg:tw-text-center">
                        <p className="tw-mt-6 tw-text-lg tw-leading-8 tw-text-gray-600">
                            Our mission is simple: to be the go-to hub for {props.breedName} lovers, providing a wealth of resources, 
                            information, and entertainment tailored specifically for this beloved breed.
                        </p>
                    </div>

                    {/* FEATURES */}
                    <div className="tw-mx-auto tw-mt-16 tw-max-w-3xl sm:tw-mt-20 lg:tw-mt-24 lg:tw-max-w-4xl">
                        <h5>What We Have:</h5>
                        <dl className="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-y-16">
                            {features.map((item) => (
                            <div key={item.name} className="tw-relative tw-pl-16">
                                <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">
                                <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-yellow-800">
                                    {/*
                                    <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                    */}
                                </div>
                                {item.name}
                                </dt>
                                <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{item.description}</dd>
                            </div>
                            ))}
                        </dl>
                    </div>

                    {/* MORE */}
                    <div className="tw-mx-auto tw-max-w-5xl lg:tw-text-center bg-light tw-py-10 tw-px-5">
                        <p>
                            We're dedicated to fostering a vibrant and supportive community of {props.breedName} enthusiasts. Whether you're a 
                            seasoned owner, a prospective adopter, or simply someone who adores these charming little dogs, you'll find a warm 
                            welcome here.
                        </p>

                        <p>
                            Join us on this exciting journey as we celebrate the beauty, joy, and unique characteristics of the {props.breedName} breed. 
                            Together, let's spread love, knowledge, and appreciation for these delightful companions who bring so much happiness into our lives.
                        </p>

                        <p>
                            Thank you for visiting. We hope you enjoy exploring our content and connecting with fellow {props.breedName} enthusiasts. 
                            Here's to many wagging tails and endless cuddles ahead!
                        </p>
                    </div>

                </div>
            </div>
            
        </LayoutMaster>
    );

}

const mapStateToProps = (state: any) => ({
    breedName : state.homeReducer.breedName,
});
  
export default connect(mapStateToProps)(AboutMain);

