import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LOAD_NEWS, loadNewsSuccess, loadNewsFailed } from './actions';

const apiUrl = process.env.REACT_APP_API_URL;

//////////////////////////
// loadNews()
//////////////////////////
function* loadNews(action: any) {
  try {
    //console.log('home/saga::loadNews()');

    var news_id = action.payload.news_id;
    const { data } = yield call(axios.post, apiUrl+ 'news/'+ news_id, action.payload);
    //console.log('--data--');
    //console.log(data);
    yield put(loadNewsSuccess(data));    
  } catch (error) {
    console.log('saga.loadNews() error');
    console.log(error);
    yield put(loadNewsFailed('An issue was encountered while loading the news.'));
  }
}


//////////////////////////
// rootSaga()
//////////////////////////
export default function* rootSaga() {
  yield all([takeLatest(LOAD_NEWS, loadNews)]);
}
