import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutMaster from '../../layouts/LayoutMaster';

const cdnUrl      = process.env.REACT_APP_CDN_URL;  
const imgTerms    = cdnUrl+ '/img/offer.jpg';
const styleTerms  = {
    backgroundImage     : `url(${imgTerms})`,
    marginTop           : "0px",
    backgroundSize      : "cover",
    backgroundRepeat    : "no-repeat",
    backgroundPosition  : "center",
};




const collection = [
    {        
      name: 'Personal Information',
      description:
        'When you visit our website, we may collect personal information such as your name, email address, and location if you choose to provide it voluntarily, for example, when signing up for newsletters or submitting adoption listings.',
      //icon: CloudArrowUpIcon,
    },
    {
      name: 'Usage Information',
      description:
        'We may collect non-personal information about your interactions with our website, such as your IP address, browser type, and pages visited. This information helps us improve our website and tailor your experience.',
      //icon: LockClosedIcon,
    },
]

const howWeUse = [
    {        
      name: 'Communication',
      description:
        'We may use your personal information to communicate with you, including sending newsletters, updates about our website, or responding to inquiries.',
      //icon: CloudArrowUpIcon,
    },
    {
      name: 'Improvement of Services',
      description:
        'Your feedback and usage data help us enhance our services, content, and user experience.',
      //icon: LockClosedIcon,
    },
    {
        name: 'Adoption Listings',
        description:
          'If you submit adoption listings, your contact information will be used to facilitate communication between potential adopters and yourself.',
        //icon: LockClosedIcon,
      },
  ]

  const others = [
    {        
      name: 'Third-Party Disclosure',
      description:
        'We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or to facilitate adoption inquiries.',
      //icon: CloudArrowUpIcon,
    },
    {
      name: 'Data Security',
      description:
        'We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is completely secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.',
      //icon: LockClosedIcon,
    },
    {
        name: 'Your Choices',
        description:
          'You have the right to opt-out of receiving communications from us, and you can update or delete your personal information by contacting us.',
        //icon: LockClosedIcon,
      },
      {
        name: 'Changes to This Privacy Policy',
        description:
          'We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the revised date will be indicated at the top of the page.',
        //icon: LockClosedIcon,
      },
  ]
  


interface Props {
    breedName   : string,
    contactEmail: string
}

const PrivacyMain = (props:Props) => {

    ///////////////////////////
    // render()
    ///////////////////////////
    return (
        <LayoutMaster>

            {/* PRIVACY: Top */}
            <div className="container-fluid bg-offer" style={styleTerms}>
                <div className="container py-5">
                    <div className="row gx-5 justify-content-start">
                        <div className="col-lg-7">
                            <div className="border-start border-5 border-dark ps-5 mb-5">
                                <h6 className="text-white text-uppercase">Privacy Policy</h6>
                                <h1 className="display-5 text-white mb-0">
                                We are committed to respecting and protecting your privacy. 
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="tw-bg-white sm:tw-py-32">
                <div className="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">

                    {/* DETAILS */}
                    <div className="tw-mx-auto tw-max-w-5xl lg:tw-text-center bg-light tw-py-10 tw-px-5">
                        <p>
                            This Privacy Policy outlines the types of personal information we collect, 
                            how it is used, and how we safeguard your information. By accessing or using our website, you agree to the terms of this Privacy Policy.
                        </p>
                    </div>

                    {/* Information We Collect */}
                    <div className="tw-mx-auto tw-mt-16 tw-max-w-3xl sm:tw-mt-20 lg:tw-mt-24 lg:tw-max-w-4xl">
                        <h5>Information We Collect</h5>
                        <dl className="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-y-16">
                            {collection.map((item) => (
                            <div key={item.name} className="tw-relative tw-pl-16">
                                <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">
                                <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-yellow-800">
                                    {/*
                                    <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                    */}
                                </div>
                                {item.name}
                                </dt>
                                <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{item.description}</dd>
                            </div>
                            ))}
                        </dl>
                    </div>

                    {/* How We Use Your Information */}
                    <div className="tw-mx-auto tw-mt-16 tw-max-w-3xl sm:tw-mt-20 lg:tw-mt-24 lg:tw-max-w-4xl">
                        <h5>How We Use Your Information</h5>
                        <dl className="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-y-16">
                            {howWeUse.map((item) => (
                            <div key={item.name} className="tw-relative tw-pl-16">
                                <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">
                                <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-yellow-800">
                                    {/*
                                    <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                    */}
                                </div>
                                {item.name}
                                </dt>
                                <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{item.description}</dd>
                            </div>
                            ))}
                        </dl>
                    </div>

                    {/* How We Use Your Information */}
                    <div className="tw-mx-auto tw-mt-16 tw-max-w-3xl sm:tw-mt-20 lg:tw-mt-24 lg:tw-max-w-4xl">
                        <h5>Others</h5>
                        <dl className="tw-grid tw-max-w-xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-max-w-none lg:tw-grid-cols-2 lg:tw-gap-y-16">
                            {others.map((item) => (
                            <div key={item.name} className="tw-relative tw-pl-16">
                                <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">
                                <div className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-yellow-800">
                                    {/*
                                    <feature.icon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                                    */}
                                </div>
                                {item.name}
                                </dt>
                                <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-600">{item.description}</dd>
                            </div>
                            ))}
                        </dl>
                    </div>

                    {/* MORE */}
                    <div className="tw-mx-auto tw-max-w-5xl lg:tw-text-center bg-light tw-py-10 tw-px-5">
                        <p>
                            If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href='mailto:{props.contactEmail}'>{props.contactEmail}</a>.
                        </p>
                    </div>


                </div>
            </div>
            
        </LayoutMaster>
    );

}

const mapStateToProps = (state: any) => ({
    breedName   : state.homeReducer.breedName,
    contactEmail: state.homeReducer.contactEmail
});
  
export default connect(mapStateToProps)(PrivacyMain);


